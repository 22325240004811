import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import AppAppBar from './components/AppAppBar'
import { Blueprint } from './components/GalacticBlueprints'
import Journal from './pages/Journal'
import LandingPage from './pages/LandingPage'
import { userFirestore } from './services/firestore'
import getLPTheme from './services/getLPTheme'
import { userLocalStorage } from './services/localStorage'

const targetDate = dayjs('2024-03-22')

export type User = {
  id: string
}

const App: React.FC = () => {
  const [userEmail, setUserEmail] = useState<string>()
  const [userId, setUserId] = useState<string>()
  const [tab, setTab] = useState<number>(0)
  const [enlistedBlueprint, setEnlistedBlueprint] = useState<Blueprint>()
  const [remainingDays, setRemainingDays] = React.useState<number>(42)
  const [users, setUsers] = React.useState<User[]>()

  React.useEffect(() => {
    if (tab === 1) {
      const now = dayjs()
      const difference = targetDate.diff(now)

      if (difference < 0) {
        setRemainingDays(0)
        return
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      setRemainingDays(days)
    }
  }, [tab])

  React.useEffect(() => {
    const retrievePreviousSession = async () => {
      const savedUserId = userLocalStorage.getUserId()
      if (savedUserId) {
        const docRef = await userFirestore.retrieveUserById(savedUserId)
        setUserId(docRef.id)
        setUserEmail(docRef.get('email'))
      }
    }
    retrievePreviousSession()
  }, [])

  const retrieveUsers = async () => {
    const querySnapshot = await userFirestore.embarkedUsers()
    setUsers(
      querySnapshot.docs.map(
        (doc): User => ({
          id: doc.id,
        }),
      ),
    )
  }

  React.useEffect(() => {
    retrieveUsers()
  }, [])

  const LPtheme = createTheme(getLPTheme('dark'))

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <AppAppBar
        setTab={setTab}
        tab={tab}
        remainingDays={remainingDays}
        enlistedBlueprint={enlistedBlueprint}
      />
      {tab === 0 ? (
        <LandingPage
          userId={userId}
          setUserId={setUserId}
          userEmail={userEmail}
          setUserEmail={setUserEmail}
          users={users}
          enlistedBlueprint={enlistedBlueprint}
          setEnlistedBlueprint={setEnlistedBlueprint}
        />
      ) : null}
      {tab === 1 ? (
        <Journal
          enlistedBlueprint={enlistedBlueprint}
          setEnlistedBlueprint={setEnlistedBlueprint}
          remainingDays={remainingDays}
        />
      ) : null}
    </ThemeProvider>
  )
}

export default App
