import bp_01_malin from './4PbApb7wSLmpgP29R5Aq.jpg'
import bp_02_alberto from './JCVg32pYyAPg98rinJse.jpg'
import bp_03_tony from './KGS59XKJ1Ui18Cld3Awd.jpg'
import bp_04_alexander from './Vb1xRZ7lqVIFOXOXbFtR.jpg'
import bp_05_suraj from './hKz8DMd2aUDfzGSH1Iwp.jpg'
import bp_06_sujata from './pYFQMHYTN2wfG47hE3vB.jpg'
import bp_07_dragosh from './wT8DMLovZofFUYt0JSD4.jpg'
import bp_08_alejandro from './xVduv1H39bpZCeBmGzNQ.jpg'
import bp_09_steve from './xjC1oa4Pqm7J6U5tFuoA.jpg'
import bp_10_johan from './DDCRrhUUl9EyQijw1wsn.jpg'

export type BoardingPass = keyof typeof boardingPasses

export const boardingPasses = {
  '4PbApb7wSLmpgP29R5Aq': bp_01_malin,
  JCVg32pYyAPg98rinJse: bp_02_alberto,
  KGS59XKJ1Ui18Cld3Awd: bp_03_tony,
  Vb1xRZ7lqVIFOXOXbFtR: bp_04_alexander,
  hKz8DMd2aUDfzGSH1Iwp: bp_05_suraj,
  pYFQMHYTN2wfG47hE3vB: bp_06_sujata,
  wT8DMLovZofFUYt0JSD4: bp_07_dragosh,
  xVduv1H39bpZCeBmGzNQ: bp_08_alejandro,
  xjC1oa4Pqm7J6U5tFuoA: bp_09_steve,
  DDCRrhUUl9EyQijw1wsn: bp_10_johan,
  HNlc5dGfpT75TgWAAUeH: bp_08_alejandro,
}

export const registeredUsers: BoardingPass[] = [
  '4PbApb7wSLmpgP29R5Aq',
  'JCVg32pYyAPg98rinJse',
  'KGS59XKJ1Ui18Cld3Awd',
  'Vb1xRZ7lqVIFOXOXbFtR',
  'hKz8DMd2aUDfzGSH1Iwp',
  'pYFQMHYTN2wfG47hE3vB',
  'wT8DMLovZofFUYt0JSD4',
  'xVduv1H39bpZCeBmGzNQ',
  'xjC1oa4Pqm7J6U5tFuoA',
  'DDCRrhUUl9EyQijw1wsn',
  'HNlc5dGfpT75TgWAAUeH',
]

export type Usernames = keyof typeof boardingPasses
export const usernames = {
  '4PbApb7wSLmpgP29R5Aq': 'Malin',
  JCVg32pYyAPg98rinJse: 'betoman',
  KGS59XKJ1Ui18Cld3Awd: 'TonyGrr',
  Vb1xRZ7lqVIFOXOXbFtR: 'Sidechain',
  hKz8DMd2aUDfzGSH1Iwp: 'Sting',
  pYFQMHYTN2wfG47hE3vB: 's2t2',
  wT8DMLovZofFUYt0JSD4: 'dcx86',
  xVduv1H39bpZCeBmGzNQ: 'alexandro',
  xjC1oa4Pqm7J6U5tFuoA: 'designerofthings',
  DDCRrhUUl9EyQijw1wsn: 'johan',
  HNlc5dGfpT75TgWAAUeH: 'alexandro',
}
