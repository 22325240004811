import firebase from 'firebase/compat/app'
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from 'firebase/firestore'
import { Blueprint, BlueprintDraft } from '../components/GalacticBlueprints'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
}

const app = firebase.initializeApp(firebaseConfig)

const db = getFirestore(app)

export const userFirestore = {
  createUser: (email: string) =>
    addDoc(collection(db, 'users'), { email, createdAt: Date.now(), embarked: true }),
  retrieveUserById: (userId: string) => getDoc(doc(db, 'users', userId)),
  retrieveUserByEmail: (email: string) => {
    const usersRef = collection(db, 'users')
    const q = query(usersRef, where('email', '==', email))
    return getDocs(q)
  },
  embarkUser: (userId: string) =>
    updateDoc(doc(db, 'users', userId), { updatedAt: Date.now(), embarked: true }),
  embarkedUsers: () => {
    const usersRef = collection(db, 'users')
    const q = query(usersRef, where('embarked', '==', true))
    return getDocs(q)
  },
}

export const blueprintFirestore = {
  retrieveBlueprints: () => getDocs(collection(db, 'projects')),
  retrieveBlueprintById: (projectId: string) => getDoc(doc(db, 'projects', projectId)),
  enlistMember: (userId: string, projectId: string) => {
    const projectRef = doc(db, 'projects', projectId)
    return updateDoc(projectRef, {
      members: arrayUnion(userId),
    })
  },
  dischargeMember: (userId: string, projectId: string) => {
    const projectRef = doc(db, 'projects', projectId)
    return updateDoc(projectRef, {
      members: arrayRemove(userId),
    })
  },
  addBlueprint: (project: BlueprintDraft) =>
    addDoc(collection(db, 'projects'), {
      ...project,
      createdAt: Date.now(),
      order42: [
        7, 15, 32, 4, 10, 40, 41, 42, 18, 31, 5, 38, 20, 27, 16, 29, 25, 33, 34, 1, 6, 21, 9, 19,
        17, 22, 39, 37, 2, 3, 12, 30, 14, 26, 13, 28, 24, 8, 11, 23, 36, 35,
      ],
    }),
  updateBlueprint: (project: Blueprint) =>
    updateDoc(doc(db, 'projects'), { ...project, updatedAt: Date.now() }),
  addJournalEntry: (projectId: string, entry: string) => {
    const projectRef = doc(db, 'projects', projectId)
    return updateDoc(projectRef, {
      journal: arrayUnion(entry),
    })
  },
}

// function generateShuffleArray() {
//   const array = [
//     1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
//     27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
//   ]
//   for (let i = array.length - 1; i > 0; i--) {
//     const j = Math.floor(Math.random() * (i + 1))
//     const iItem = array[i]
//     const jItem = array[j]
//     array[i] = jItem
//     array[j] = iItem
//   }
//   const shuffledArray = Array.from(new Set(array))
//   console.log(shuffledArray)
//   return shuffledArray
// }
