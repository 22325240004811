import FacebookIcon from '@mui/icons-material/Facebook'
import GithubIcon from '@mui/icons-material/GitHub'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/X'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const logoStyle = {
  width: '140px',
  height: 'auto',
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="https://mui.com/">galacticcoders.com&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  )
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}>
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Typography
              component="span"
              variant="h5"
              style={logoStyle}
              sx={{
                color: theme => (theme.palette.mode === 'light' ? 'primary.main' : 'primary.light'),
                alignSelf: 'center',
                textAlign: 'center',
              }}>
              HACKQUINOX
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}>
          <Typography variant="body2" fontWeight={600}>
            EventHorizon
          </Typography>
          <Link color="text.secondary" href="#">
            CosmicPerks
          </Link>
          <Link color="text.secondary" href="#">
            Galaximonials
          </Link>
          <Link color="text.secondary" href="#">
            StelarArchives
          </Link>
          <Link color="text.secondary" href="#">
            GalacticBlueprints
          </Link>
          <Link color="text.secondary" href="#">
            CCQ
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}>
        <div>
          <Copyright />
        </div>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}>
          <IconButton
            color="inherit"
            href="https://github.com/galacticcoders"
            aria-label="GitHub"
            sx={{ alignSelf: 'center' }}>
            <GithubIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://social.galacticcoders.com/public/local"
            aria-label="X"
            sx={{ alignSelf: 'center' }}>
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://social.galacticcoders.com/public/local"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}>
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://social.galacticcoders.com/public/local"
            aria-label="X"
            sx={{ alignSelf: 'center' }}>
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://social.galacticcoders.com/public/local"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}>
            <InstagramIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  )
}
