import Masonry from '@mui/lab/Masonry'
import { useMediaQuery } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import leo from '../assets/galaximonials/leo_lightyear.png'
import max from '../assets/galaximonials/max_meteor.png'
import nova from '../assets/galaximonials/nova_nightingale.png'
import orion from '../assets/galaximonials/orion_oracle.png'
import sirius from '../assets/galaximonials/sirius_cypher.png'
import zara from '../assets/galaximonials/zara_quasar.png'

const galaximonials = [
  {
    avatar: <Avatar alt="Zara Quasar" src={zara} />,
    name: 'Zara Quasar',
    occupation: 'Intergalactic Debugging Specialist',
    testimonial:
      "I coded a 'Hello World' so powerful, it created a glitch in the matrix. Now, every time I sneeze, my smart fridge orders more pizza. Best. Bug. Ever.",
  },
  {
    avatar: <Avatar alt="Leo Lightyear" src={leo} />,
    name: 'Leo Lightyear',
    occupation: 'Chief Moonlight Strategist',
    testimonial:
      "Thanks to this retreat, I found a coding partner who also believes in coding by moonlight. We've invented a new language called Luna++. It's not functional, but it glows in the dark!",
  },
  {
    avatar: <Avatar alt="Max Meteor" src={max} />,
    name: 'Max Meteor',
    occupation: 'Extra-Terrestrial Liaison',
    testimonial:
      "I was in the middle of a code review when an alien asked for help with their JavaScript. Turns out 'undefined' is the same in every galaxy. Mind-blowing!",
  },
  {
    avatar: <Avatar alt="Nova Nightingale" src={nova} />,
    name: 'Nova Nightingale',
    occupation: 'AI Whisperer',
    testimonial:
      "The AI bot I developed here is so advanced, it started fixing my personal life. It scheduled a date for me. I'm not even mad, that's amazing!",
  },
  {
    avatar: <Avatar alt="Sirius Cypher" src={sirius} />,
    name: 'Sirius Cypher',
    occupation: 'Temporal Network Engineer',
    testimonial:
      "I was told the Wi-Fi would be 'spotty' but didn't expect an interdimensional portal in the cafeteria. Now my emails come from the future. Spoiler: Friday's lunch is taco day!",
  },
  {
    avatar: <Avatar alt="Orion Oracle" src={orion} />,
    name: 'Orion Oracle',
    occupation: 'Stellar Theoretical Physicist',
    testimonial:
      "After attending this retreat, I wrote an algorithm that accidentally solved the mystery of black holes. Spoiler: they're just cosmic trash bins.",
  },
]

const logoStyle = {
  width: '64px',
  opacity: 0.3,
}

export default function Galaximonials() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const columns = isSmallScreen ? 1 : 3

  return (
    <Container
      id="Galaximonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}>
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}>
        <Typography component="h2" variant="h4" color="text.primary">
          Galaximonials
        </Typography>
        <Typography variant="body1" color="text.secondary">
          See how we warp the very fabric of coding retreats with humor, camaraderie, and a stellar
          experience. Join the Galactic Coders for an adventure in code, laughter, and intergalactic
          support.
        </Typography>
      </Box>
      <Masonry columns={columns} spacing={2}>
        {galaximonials.map((galaximonial, index) => (
          <Card key={index} sx={{ p: 1 }}>
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {galaximonial.testimonial}
              </Typography>
            </CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                pr: 2,
              }}>
              <CardHeader
                avatar={galaximonial.avatar}
                title={galaximonial.name}
                subheader={galaximonial.occupation}
              />
            </Box>
          </Card>
        ))}
      </Masonry>
    </Container>
  )
}
