import AirlineSeatIndividualSuiteRoundedIcon from '@mui/icons-material/AirlineSeatIndividualSuiteRounded'
import CelebrationRoundedIcon from '@mui/icons-material/CelebrationRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import H2024 from '../assets/hackquinox_2024.jpg'

const perks = [
  ' 🌌 Socializing: Meet like-minded beings from all corners of our STEM galaxy.  ',
  ' 🔭 Steam: Steam up new ideas where science, tech, engineering, and math collide.  ',
  ' 📘 Learning: Expand your universe with shared knowledge, no black holes of pressure.  ',
  " 🤝 Sharing: Present what you're proud of - a project, a concept, or just your presence.  ",
  ' 🎉 Partying: Let loose in the nebula of celebration, where fun is the brightest star.  ',
  ' 🏆 Achievement: Recognize the journey taken, not just the destination reached.  ',
  ' 👫 Friendship: Build bonds that last lightyears.  ',
  ' 🔜 Looking Forward: Leave excited for our next cosmic rendezvous.  ',
]

const items = [
  {
    icon: <AirlineSeatIndividualSuiteRoundedIcon />,
    title: 'Cosmic Quarters for Two Nights:',
    description:
      'Each space station is equipped with bed linen and star cloths. Communal cosmic cleanse zones (bathrooms and showers) are shared with fellow interstellar travelers.',
    imageLight: `url(${H2024})`,
    imageDark: `url(${H2024})`,
  },
  {
    icon: <RestaurantRoundedIcon />,
    title: 'Galactic Grub Odyssey:',
    description:
      'Embark on a culinary voyage with 2 celestial breakfasts featuring fresh bread, 1 delectable lunch, 2 starlit buffet dinners, and 1 afternoon fika for a delightful cosmic pause.',
    imageLight: `url(${H2024})`,
    imageDark: `url(${H2024})`,
  },
  {
    icon: <CelebrationRoundedIcon />,
    title: 'Equinox Extravaganza',
    description:
      "This gala is your portal to unwind, mingle, and toast to our hackathon's cosmic achievements. With a soundtrack from the future and a laid-back vibe, it's the perfect nebula of fun and fellowship to conclude our interstellar journey and forge connections within the tech cosmos.",
    imageLight: `url(${H2024})`,
    imageDark: `url(${H2024})`,
  },
]

type CosmicPerksProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CosmicPerks: React.FC<CosmicPerksProps> = ({ setOpen }) => {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0)

  const handleItemClick = (index: number) => {
    setSelectedItemIndex(index)
  }

  const selectedFeature = items[selectedItemIndex]

  return (
    <Container id="CosmicPerks" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              CosmicPerks
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: { xs: 2, sm: 4 } }}>
              The entire cosmos of Baggå Skola's cafe is reserved exclusively for our celestial
              travelers. Included in your journey are the following stellar amenities:
            </Typography>
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: theme => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : ''
                    }
                    return selectedItemIndex === index ? 'primary.light' : ''
                  },
                  background: theme => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : ''
                    }
                    return selectedItemIndex === index ? 'none' : ''
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}>
            <Box
              sx={{
                backgroundImage: theme =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="text.primary" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}>
                <span>Learn more</span>
                <ChevronRightRoundedIcon fontSize="small" sx={{ mt: '1px', ml: '2px' }} />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}>
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor: selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: theme => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : 'grey.200'
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800'
                  },
                }}>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}>
                  <Box
                    sx={{
                      color: theme => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index ? 'primary.main' : 'grey.300'
                        }
                        return selectedItemIndex === index ? 'primary.main' : 'grey.700'
                      },
                    }}>
                    {icon}
                  </Box>
                  <div>
                    <Typography color="text.primary" variant="body2" fontWeight="bold">
                      {title}
                    </Typography>
                    <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                      {description}
                    </Typography>
                    <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={event => {
                        event.stopPropagation()
                        setOpen(true)
                      }}>
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon fontSize="small" sx={{ mt: '1px', ml: '2px' }} />
                    </Link>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}>
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundImage: theme =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          marginTop: 5,
          flexDirection: 'column',
          gap: 2,
        }}>
        {perks.map(perk => (
          <Box style={{ alignItems: 'center', display: 'flex' }}>
            <Box style={{ flex: 1 }} />
            <Typography variant="body1" color="text.primary" style={{ width: '50%' }}>
              {perk}
            </Typography>
            <Box style={{ flex: 1 }} />
          </Box>
        ))}
      </Box>
    </Container>
  )
}

export default CosmicPerks
