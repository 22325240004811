const HACKQUINOX_USER_ID = 'HACKQUINOX_USER_ID'
export const userLocalStorage = {
  saveUserId: (userId: string): void => localStorage.setItem(HACKQUINOX_USER_ID, userId),
  getUserId: (): string | null => localStorage.getItem(HACKQUINOX_USER_ID),
}

const HACKQUINOX_PROJECT_ID = 'HACKQUINOX_PROJECT_ID'
export const projectLocalStorage = {
  setProjectId: (projectId: string): void => localStorage.setItem(HACKQUINOX_PROJECT_ID, projectId),
  getProjectId: (): string | null => localStorage.getItem(HACKQUINOX_PROJECT_ID),
}
