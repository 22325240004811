import { Modal, alpha } from '@mui/material'
import Box from '@mui/material/Box'
import * as React from 'react'
import H2024 from '../assets/hackquinox_2024.jpg'

type PosterModalProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PosterModal: React.FC<PosterModalProps> = ({ open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box
        sx={{
          p: { xs: 4, sm: 10 },
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Box
          onClick={() => setOpen(false)}
          sx={{
            outline: '1px solid',
            outlineColor: alpha('#000000', 0.1),
            m: 'auto',
            width: '100%',
            height: '100%',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${H2024})`,
          }}></Box>
      </Box>
    </Modal>
  )
}

export default PosterModal
