import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { Blueprint } from './GalacticBlueprints'

const logoStyle = {
  width: '160px',
  height: 'auto',
  cursor: 'pointer',
}

type AppAppBarProps = {
  tab: number
  setTab: React.Dispatch<React.SetStateAction<number>>
  remainingDays: number
  enlistedBlueprint: Blueprint | undefined
}

const AppAppBar: React.FC<AppAppBarProps> = ({
  tab,
  setTab,
  remainingDays,
  enlistedBlueprint,
}: AppAppBarProps) => {
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen)
  }

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId)
    const offset = 128
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset
      sectionElement.scrollIntoView({ behavior: 'smooth' })
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      })
      setOpen(false)
    }
  }

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}>
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={theme => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: 2,
              bgcolor: 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}>
              <Typography
                onClick={() => {
                  scrollToSection('hero')
                  setTab(0)
                }}
                component="span"
                variant="h5"
                style={logoStyle}
                sx={{
                  color: 'primary.light',
                  alignSelf: 'center',
                  textAlign: 'center',
                }}>
                HACKQUINOX
              </Typography>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <MenuItem
                  onClick={() => {
                    scrollToSection('CosmicPerks')
                    setTab(0)
                  }}
                  sx={{ py: '6px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    CosmicPerks
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    scrollToSection('Galaximonials')
                    setTab(0)
                  }}
                  sx={{ py: '6px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    Galaximonials
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    scrollToSection('StelarArchives')
                    setTab(0)
                  }}
                  sx={{ py: '6px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    StelarArchives
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    scrollToSection('GalacticBlueprints')
                    setTab(0)
                  }}
                  sx={{ py: '6px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    GalacticBlueprints
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    scrollToSection('CCQ')
                    setTab(0)
                  }}
                  sx={{ py: '6px', px: '12px' }}>
                  <Typography variant="body2" color="text.primary">
                    CCQ
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            {enlistedBlueprint ? (
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  gap: 0.5,
                  alignItems: 'center',
                }}>
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  onClick={() => setTab(tab === 1 ? 0 : 1)}>
                  {tab === 1 ? `Day ${remainingDays}` : 'Journal'}
                </Button>
              </Box>
            ) : null}
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}>
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}>
                  <MenuItem
                    onClick={() => {
                      scrollToSection('CosmicPerks')
                      setTab(0)
                    }}>
                    CosmicPerks
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      scrollToSection('Galaximonials')
                      setTab(0)
                    }}>
                    Galaximonials
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      scrollToSection('StelarArchives')
                      setTab(0)
                    }}>
                    StelarArchives
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      scrollToSection('GalacticBlueprints')
                      setTab(0)
                    }}>
                    GalacticBlueprints
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      scrollToSection('CCQ')
                      setTab(0)
                    }}>
                    CCQ
                  </MenuItem>
                  {enlistedBlueprint ? (
                    <>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          scrollToSection('CCQ')
                          setTab(tab === 1 ? 0 : 1)
                        }}>
                        {tab === 1 ? `Day ${remainingDays}` : 'Journal'}
                      </MenuItem>
                    </>
                  ) : null}
                  <Divider />
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

export default AppAppBar
