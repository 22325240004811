import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { Usernames, usernames } from '../assets/tickets'
import { blueprintFirestore } from '../services/firestore'

export type Blueprint = {
  id: string
  title: string
  description: string
  members?: string[]
  createdAt: number
  tags?: string
  order42: number[]
  journal?: string[]
}

export type BlueprintDraft = {
  title?: string
  description?: string
  tags?: string
}

type GalacticBlueprintsProps = {
  userId: string | undefined
  enlistedBlueprint: Blueprint | undefined
  setEnlistedBlueprint: React.Dispatch<React.SetStateAction<Blueprint | undefined>>
}

const GalacticBlueprints: React.FC<GalacticBlueprintsProps> = ({
  userId,
  enlistedBlueprint,
  setEnlistedBlueprint,
}) => {
  const [blueprints, setBlueprints] = React.useState<Blueprint[]>()
  const [blueprintDraft, setBlueprintDraft] = React.useState<BlueprintDraft | null>()

  const retrieveBlueprints = async () => {
    const querySnapshot = await blueprintFirestore.retrieveBlueprints()
    setBlueprints(
      querySnapshot.docs
        .map(
          (doc): Blueprint => ({
            id: doc.id,
            title: doc.get('title'),
            description: doc.get('description'),
            members: doc.get('members'),
            tags: doc.get('tags'),
            createdAt: doc.get('createdAt'),
            order42: doc.get('order42'),
            journal: doc.get('journal'),
          }),
        )
        .sort((a, b) => a.createdAt - b.createdAt),
    )
  }

  React.useEffect(() => {
    retrieveBlueprints()
  }, [])

  React.useEffect(() => {
    if (userId && blueprints) {
      setEnlistedBlueprint(blueprints.find(blueprint => blueprint.members?.includes(userId)))
    }
  }, [blueprints, userId])

  const onPressEnlistNow = async (blueprint: Blueprint) => {
    if (userId) {
      await blueprintFirestore.enlistMember(userId, blueprint.id)
      retrieveBlueprints()
    }
  }

  const onPressDischargeNow = async (blueprint: Blueprint) => {
    if (userId) {
      await blueprintFirestore.dischargeMember(userId, blueprint.id)
      retrieveBlueprints()
    }
  }

  const onClickSaveBlueprint = async () => {
    if (userId && blueprintDraft) {
      const projectRef = await blueprintFirestore.addBlueprint(blueprintDraft)
      blueprintFirestore.enlistMember(userId, projectRef.id)
      retrieveBlueprints()
      setBlueprintDraft(null)
    }
  }

  return (
    <Container
      id="GalacticBlueprints"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}>
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}>
        <Typography component="h2" variant="h4" color="text.primary">
          GalacticBlueprints
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Enlist on a journey of discovery with our constellation of project ideas designed to
          challenge and expand your coding horizons. Galatic Blueprints offers a launchpad for
          innovation, inviting you to contribute your unique vision to the coding cosmos. Whether
          you're looking to solve interstellar puzzles or create digital ecosystems, your next epic
          quest awaits.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {blueprints &&
          blueprints
            .filter(blueprint => {
              if (enlistedBlueprint) {
                return blueprint.members?.length
              }
              return blueprint
            })
            .map(blueprint => (
              <Grid item key={blueprint.id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    border: blueprint.members?.includes(userId || '') ? '1px solid' : undefined,
                    borderColor: blueprint.members?.includes(userId || '')
                      ? 'primary.main'
                      : undefined,
                    background: blueprint.members?.includes(userId || '')
                      ? 'linear-gradient(#033363, #021F3B)'
                      : undefined,
                  }}>
                  <CardContent>
                    <Box
                      sx={{
                        mb: 1,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        color: blueprint.members?.includes(userId || '')
                          ? 'primary.contrastText'
                          : '',
                      }}>
                      <Typography component="h3" variant="body2">
                        {blueprint.tags}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        color: blueprint.members?.includes(userId || '')
                          ? 'primary.contrastText'
                          : undefined,
                      }}>
                      <Typography component="h4" variant="h4">
                        {blueprint.title}
                      </Typography>
                    </Box>
                    <Divider
                      sx={{
                        my: 2,
                        opacity: 0.2,
                        borderColor: 'grey.500',
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color: blueprint.members?.includes(userId || '')
                          ? 'primary.contrastText'
                          : undefined,
                      }}>
                      {blueprint.description}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        marginTop: 2,
                        gap: 1,
                        alignItems: 'center',
                        color: blueprint.members?.includes(userId || '')
                          ? 'primary.contrastText'
                          : '',
                      }}>
                      {userId &&
                        blueprint.members &&
                        blueprint.members.map(member => (
                          <Chip
                            key={member}
                            icon={<AutoAwesomeIcon />}
                            label={
                              usernames[member as Usernames]
                                ? usernames[member as Usernames]
                                : userId
                            }
                            size="small"
                            sx={{
                              background: theme => (theme.palette.mode === 'light' ? '' : 'none'),
                              backgroundColor: 'primary.contrastText',
                              '& .MuiChip-label': {
                                color: 'primary.dark',
                              },
                              '& .MuiChip-icon': {
                                color: 'primary.dark',
                              },
                            }}
                          />
                        ))}
                    </Box>
                  </CardContent>
                  {!enlistedBlueprint || blueprint.members?.includes(userId || '') ? (
                    <CardActions>
                      {userId ? (
                        <Button
                          fullWidth
                          onClick={() =>
                            blueprint.members?.includes(userId || '')
                              ? onPressDischargeNow(blueprint)
                              : onPressEnlistNow(blueprint)
                          }
                          variant={
                            blueprint.members?.includes(userId || '') ? 'contained' : 'outlined'
                          }>
                          {blueprint.members?.includes(userId || '') ? 'Discharge' : 'Enlist now'}
                        </Button>
                      ) : null}
                    </CardActions>
                  ) : null}
                </Card>
              </Grid>
            ))}
        {userId && !enlistedBlueprint ? (
          <Grid item xs={12} sm={6} md={4}>
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                // border: project.members?.includes(userId || '') ? '1px solid' : undefined,
                // borderColor: project.members?.includes(userId || '') ? 'primary.main' : undefined,
                // background: project.members?.includes(userId || '')
                //   ? 'linear-gradient(#033363, #021F3B)'
                //   : undefined,
              }}>
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // color: project.members?.includes(userId || '') ? 'primary.contrastText' : '',
                  }}>
                  <TextField
                    required
                    id="tags"
                    multiline
                    value={blueprintDraft ? blueprintDraft?.tags : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setBlueprintDraft({ ...blueprintDraft, tags: event.target.value })
                    }}
                    name="tags"
                    label="Tags"
                    fullWidth
                    variant="standard"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    // color: project.members?.includes(userId || '')
                    // ? 'primary.contrastText'
                    // : undefined,
                  }}>
                  <TextField
                    required
                    id="title"
                    name="title"
                    label="Title"
                    value={blueprintDraft ? blueprintDraft.title : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setBlueprintDraft({ ...blueprintDraft, title: event.target.value })
                    }}
                    multiline
                    fullWidth
                    variant="standard"
                  />
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                <TextField
                  required
                  id="description"
                  name="description"
                  value={blueprintDraft ? blueprintDraft.description : ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setBlueprintDraft({ ...blueprintDraft, description: event.target.value })
                  }}
                  label="Description"
                  multiline
                  fullWidth
                  variant="standard"
                />
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  onClick={onClickSaveBlueprint}
                  disabled={!blueprintDraft}
                  variant={'contained'}>
                  Save
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </Container>
  )
}

export default GalacticBlueprints
