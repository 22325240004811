import { Card } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import LKGCC from '../assets/low-key-galactic-coders-community.png'
import { BoardingPass, boardingPasses, registeredUsers } from '../assets/tickets'
import { userFirestore } from '../services/firestore'
import { userLocalStorage } from '../services/localStorage'

type HeroProps = {
  userId: string | undefined
  setUserId: React.Dispatch<React.SetStateAction<string | undefined>>
  userEmail: string | undefined
  setUserEmail: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Hero: React.FC<HeroProps> = ({ userId, setUserId, userEmail, setUserEmail }) => {
  const onPressEmbarkNow = async () => {
    if (userEmail) {
      const documentsRef = await userFirestore.retrieveUserByEmail(userEmail.toLocaleLowerCase())
      if (documentsRef.empty) {
        const { id } = await userFirestore.createUser(userEmail.toLocaleLowerCase())
        userLocalStorage.saveUserId(id)
        setUserId(id)
      } else {
        const docRef = documentsRef.docs[0]
        setUserId(docRef.id)
        setUserEmail(docRef.get('email'))
        userLocalStorage.saveUserId(docRef.id)
        userFirestore.embarkUser(docRef.id)
      }
    }
  }
  return (
    <Box
      id="hero"
      sx={theme => ({
        width: '100%',
        backgroundImage: `url(${LKGCC})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      })}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}>
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
            }}>
            Hackquinox&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: 'primary.light',
              }}>
              0x2EF8
            </Typography>
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.primary">
            Embark on a tranquil coding odyssey. <br /> Claim your place in the cosmic expanse,
            where inspiration streams and code breathes into existence.
          </Typography>
          {!userId ? (
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignSelf="center"
              spacing={1}
              useFlexGap
              sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}>
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                value={userEmail}
                aria-label="Enter your email address"
                onChange={email => {
                  setUserEmail(email.target.value)
                }}
                placeholder="Your email address"
                inputProps={{
                  autocomplete: 'off',
                  ariaLabel: 'Enter your email address',
                }}
              />
              <Button
                variant="contained"
                disabled={!userEmail}
                color="primary"
                onClick={onPressEmbarkNow}>
                Embark now
              </Button>
            </Stack>
          ) : null}
        </Stack>
        <Box
          id="image"
          sx={{
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>
          {userId && registeredUsers.includes(userId as BoardingPass) ? (
            <>
              <img src={boardingPasses[userId as BoardingPass]} loading="lazy" />
              <div style={{ height: 10 }} />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }} />
                <a href={boardingPasses[userId as BoardingPass]} download>
                  <Button variant={'contained'}>Download Ticket</Button>
                </a>
              </div>
            </>
          ) : null}
          {userId && !registeredUsers.includes(userId as BoardingPass) ? (
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
              }}>
              <Typography variant="h5" color="text.primary">
                Gratitude for joining our celestial circuit! A confirmation starbeam - replete with
                further enlightenment - will comet your way shortly. Prepare for an odyssey of
                tranquility and creation in the vast vault of the cosmos.
              </Typography>
            </Card>
          ) : null}
        </Box>
      </Container>
    </Box>
  )
}

export default Hero
