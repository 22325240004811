import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Card, CardActions, Grid, TextField } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import artwork from '../assets/artwork42/artwork.jpg'
import { Blueprint } from '../components/GalacticBlueprints'
import '../css/hexagon.css'
import { blueprintFirestore } from '../services/firestore'
import dayjs from 'dayjs'

type JournalProps = {
  enlistedBlueprint: Blueprint | undefined
  remainingDays: number
  setEnlistedBlueprint: React.Dispatch<React.SetStateAction<Blueprint | undefined>>
}

const Journal: React.FC<JournalProps> = ({
  enlistedBlueprint,
  setEnlistedBlueprint,
  remainingDays,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const [value, setValue] = React.useState<string>()
  const [countdown, setCountdown] = React.useState('')
  console.log(countdown)

  React.useEffect(() => {
    const updateCountdown = () => {
      const targetDate = dayjs(`2024-03-22`).subtract(remainingDays, 'day')
      const now = dayjs()
      const difference = targetDate.diff(now)

      if (difference < 0) {
        setCountdown('D-42')
        return
      }

      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((difference / (1000 * 60)) % 60)
      const seconds = Math.floor((difference / 1000) % 60)

      setCountdown(`${hours} hours, ${minutes} minutes, and ${seconds} seconds`)
    }

    const interval = setInterval(updateCountdown, 1000)

    return () => clearInterval(interval)
  }, [])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const addEntryIntoJournal = async () => {
    if (value && enlistedBlueprint) {
      await blueprintFirestore.addJournalEntry(enlistedBlueprint.id, value)
      const blueprint = await blueprintFirestore.retrieveBlueprintById(enlistedBlueprint.id)
      if (blueprint) {
        setEnlistedBlueprint({
          id: enlistedBlueprint.id,
          title: blueprint.get('title'),
          description: blueprint.get('description'),
          members: blueprint.get('members'),
          tags: blueprint.get('tags'),
          createdAt: blueprint.get('createdAt'),
          order42: blueprint.get('order42'),
          journal: blueprint.get('journal'),
        })
      }
      setValue('')
    }
  }

  if (!enlistedBlueprint) return null

  return (
    <Container
      id="Journal"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}>
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}>
        {enlistedBlueprint.title}
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Grid item xs={12} sm={6} md={4}>
          {!enlistedBlueprint.journal || enlistedBlueprint.journal.length <= 42 - remainingDays ? (
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
              }}>
              <TextField
                required
                id="entry"
                name="entry"
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setValue(event.target.value)
                }}
                multiline
                fullWidth
                variant="outlined"
              />
              <CardActions>
                <Button onClick={addEntryIntoJournal} disabled={!value} variant={'contained'}>
                  Save
                </Button>
              </CardActions>
            </Card>
          ) : (
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
              }}>
              <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
                {` Next available journal slot in ${countdown} `}
              </Typography>
            </Card>
          )}
        </Grid>
      </Box>
      <Box
        sx={{ width: '100%', bgcolor: 'background.default' }}
        style={
          !expanded
            ? {
                background: `url(${artwork})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }
            : {}
        }>
        {enlistedBlueprint.journal
          ? enlistedBlueprint.journal.map((entry, index) => (
              <Accordion
                key={index}
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}
                sx={{
                  width: '100%',
                  // bgcolor: typeof entry === 'number' ? 'transparent' : 'background.default',
                  bgcolor: 'transparent',
                }}>
                <AccordionSummary
                  style={{ backgroundColor: 'transparent' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1d-content"
                  id="panel1d-header">
                  <Typography component="h3" variant="subtitle2">
                    {`Day ${42 - index}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ maxWidth: { sm: '100%', md: '70%' } }}>
                    {entry}
                  </Typography>
                  {/* <TextField
                    required
                    id="entry"
                    name="entry"
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setValue(event.target.value)
                    }}
                    multiline
                    fullWidth
                    variant="standard"
                  /> */}
                </AccordionDetails>
              </Accordion>
            ))
          : null}
      </Box>
    </Container>
  )
}

export default Journal

// <TextField
//   required
//   id="description"
//   name="description"
//   // value={blueprintDraft ? blueprintDraft.description : ''}
//   // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//   //   setBlueprintDraft({ ...blueprintDraft, description: event.target.value })
//   // }}
//   label="Description"
//   multiline
//   fullWidth
//   variant="standard"
// />
