import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import * as React from 'react'
import CCQ from '../components/CCQ'
import CosmicPerks from '../components/CosmicPerks'
import Footer from '../components/Footer'
import GalacticBlueprints, { Blueprint } from '../components/GalacticBlueprints'
import Galaximonials from '../components/Galaximonials'
import Hero from '../components/Hero'
import StelarArchives from '../components/StelarArchives'
import TrustedDevelopers from '../components/TrustedDevelopers'
import PosterModal from '../modals/PosterModal'
import { User } from '../App'

type LandingPageProps = {
  userId: string | undefined
  setUserId: React.Dispatch<React.SetStateAction<string | undefined>>
  userEmail: string | undefined
  setUserEmail: React.Dispatch<React.SetStateAction<string | undefined>>
  users: User[] | undefined
  enlistedBlueprint: Blueprint | undefined
  setEnlistedBlueprint: React.Dispatch<React.SetStateAction<Blueprint | undefined>>
}

const LandingPage: React.FC<LandingPageProps> = ({
  userId,
  setUserId,
  userEmail,
  setUserEmail,
  users,
  enlistedBlueprint,
  setEnlistedBlueprint,
}) => {
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <>
      <PosterModal open={open} setOpen={setOpen} />
      <Hero
        userId={userId}
        setUserId={setUserId}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
      />
      <Box sx={{ bgcolor: 'background.default' }}>
        <TrustedDevelopers users={users} />
        <CosmicPerks setOpen={setOpen} />
        <Divider />
        <Galaximonials />
        <Divider />
        <StelarArchives />
        <Divider />
        <GalacticBlueprints
          userId={userId}
          enlistedBlueprint={enlistedBlueprint}
          setEnlistedBlueprint={setEnlistedBlueprint}
        />
        <Divider />
        <CCQ />
        <Divider />
        <Footer />
      </Box>
    </>
  )
}

export default LandingPage
