import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { User } from '../App'
import { FC } from 'react'
import { Usernames, usernames } from '../assets/tickets'

const developers = ['Sujata', 'Johan', 'Alberto', 'Dragosh']

const logoStyle = {
  width: '100px',
  height: '80px',
  margin: '0 32px',
  opacity: 0.7,
}

type TrustedDevelopersProps = {
  users: User[] | undefined
}

const TrustedDevelopers: FC<TrustedDevelopersProps> = ({ users }) => {
  if (!users) return null

  return (
    <Box id="TrustedDevelopers" sx={{ py: 4 }}>
      <Typography component="p" variant="subtitle2" align="center" color="text.secondary">
        Embarked
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 2, opacity: 0.6 }}>
        {users.map((user, index) => (
          <Grid item key={index}>
            <Typography
              component="span"
              variant="h5"
              style={logoStyle}
              sx={{
                color: theme => (theme.palette.mode === 'light' ? 'primary.main' : 'primary.light'),
                alignSelf: 'center',
                textAlign: 'center',
              }}>
              {usernames[user.id as Usernames]}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default TrustedDevelopers
