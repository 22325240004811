import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded'
import FlagRoundedIcon from '@mui/icons-material/FlagRounded'
import MusicNoteRoundedIcon from '@mui/icons-material/MusicNoteRounded'
import PhoneIphoneRoundedIcon from '@mui/icons-material/PhoneIphoneRounded'
import PolicyRoundedIcon from '@mui/icons-material/PolicyRounded'
import StorageRoundedIcon from '@mui/icons-material/StorageRounded'
import VideogameAssetRoundedIcon from '@mui/icons-material/VideogameAssetRounded'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const items = [
  {
    icon: <VideogameAssetRoundedIcon />,
    title: 'FruitDrop Arcade Game',
    description:
      'Fruits droping with ever increasing speed. Collect all of them and avoid the poison drops. The amazing soundtrack is crafter by our colaborator David.',
    developers: 'ERN, DRG',
    languages: 'C#',
    stack: 'Unity',
  },
  {
    icon: <MusicNoteRoundedIcon />,
    title: 'SoundMapping',
    description:
      'Pickup sound via a microphone and display it on the screen split into the different frequencies',
    developers: 'SRJ, LXN',
    languages: '',
    stack: '',
  },
  {
    icon: <PolicyRoundedIcon />,
    title: 'GoogleData Aggregator',
    description:
      'Download your own google data and agreegate it into a file that charts everything that google knows about you.',
    developers: 'TNN',
    languages: '',
    stack: '',
  },
  {
    icon: <ColorLensRoundedIcon />,
    title: 'GenerativeArt NFT',
    description:
      'Generative art based NFT collections that can be minted on publicly available exchanges. It should be possible to find it on marketplaces such as opensea for purchase.',
    developers: 'SJT',
    languages: '',
    stack: '',
  },
  {
    icon: <FlagRoundedIcon />,
    title: 'CTF Hacking Challange',
    description:
      'Ethical hacking exercises using a dedicated Juice Shop website provided by OWASP and hosted by the event organizers. This website serves as a platform for participants to showcase their skills and knowledge of security vulnerabilities and ethical hacking techniques.',
    developers: 'MLN, SJT, LBR, DRG, ERN, LXN, TNN, STV',
    languages: '',
    stack: '',
  },
  {
    icon: <VideogameAssetRoundedIcon />,
    title: 'Nirvana-Frogger-Tictactoe',
    description: 'The most awesome game ever made by humans.',
    developers: 'MLN, SJT',
    languages: '',
    stack: '',
  },
  {
    icon: <VideogameAssetRoundedIcon />,
    title: 'Game of Life',
    description: 'Programming the game of life.',
    developers: 'MLN, SJT',
    languages: '',
    stack: '',
  },
  {
    icon: <PhoneIphoneRoundedIcon />,
    title: 'Eldritch Horror Companion App',
    description: 'A companion app for Eldritch Horror board game to help with randomizing cards',
    developers: 'DRG',
    languages: 'Swift',
    stack: 'XCode',
  },
  {
    icon: <StorageRoundedIcon />,
    title: 'Automate DevOps deployment',
    description: 'Automate devops deployment using cli and some powerfull tools',
    developers: 'LXN',
    languages: '',
    stack: '',
  },
]

export default function StelarArchives() {
  return (
    <Box
      id="StelarArchives"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}>
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}>
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}>
          <Typography component="h2" variant="h4">
            StelarArchives
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Dive into a universe of completed missions that have charted new territories in coding.
            Our Stelar Archives are a testament to the ingenuity and teamwork of coders who have
            ventured before you. Explore these celestial achievements to inspire your next coding
            adventure.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}>
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400', pt: 1 }}>
                    {` ${item.developers} ${item.languages} ${item.stack} `}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
